$('#forget-pass-email').keyup(function() {
  if (emailRegex.test($('#forget-pass-email').val())){
    $('#forget-pass-email-message').html('')
    $('#forget-pass-button').removeClass('inactive').addClass('active').attr('onclick','forgetPassword()')
  } else {
    $('#forget-pass-email-message').html('Email salah')
    $('#forget-pass-button').addClass('inactive').removeClass('active').removeAttr('onclick')
  }
}).change(function() {
  if (emailRegex.test($('#forget-pass-email').val())){
    $('#forget-pass-email-message').html('')
    $('#forget-pass-button').removeClass('inactive').addClass('active').attr('onclick','forgetPassword()')
  } else {
    $('#forget-pass-email-message').html('Email salah')
    $('#forget-pass-button').addClass('inactive').removeClass('active').removeAttr('onclick')
  }
})

function forgetPassword() {
  var value = {
    email: $('#forget-pass-email').val()
  };
  initialApiRestrict('/forgot_password', value, 'post').done(function(json) {
      if (json.status == '600') {
        $('#forget-pass-message').html('E-mail berhasil dikirim').fadeIn('slow', function(){
          setTimeout(function(){
             $('#forget-pass-message').fadeOut(5000);
             $('#verify-code').parent().removeClass('collapse');
             $('#forgot-pass').parent().addClass('collapse');
           }, 1000);
        })
      }else{
        if(json.error) {
          $('#forget-pass-message').html(json.error).fadeIn('slow', function(){
            setTimeout(function(){ $('#forget-pass-message').fadeOut(5000) }, 1000);
          })
        } else {
          $('#forget-pass-message').html('E-mail gagal dikirim').fadeIn('slow', function(){
            setTimeout(function(){ $('#forget-pass-message').fadeOut(5000) }, 1000);
          })
        }
      }
  })
}

$('#overlay-forgot-pass').click(function(event) {
    if (event.target === this) {
        $('#forgot-pass').parent().addClass('collapse');
    }
});
$('#forgot-pass .close-modal').click(function(event) {
    $('#forgot-pass').parent().addClass('collapse');
});
